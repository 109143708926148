<template>
  <div id="app" :class="routerClass">
    <NotificationRefresh v-if="!isSpeach"/>
    <CheckPlugin v-if="!isSpeach"/>
    <ThemeSelector v-if="!isSpeach"/>
    <RegisterFirebaseToken v-if="!isSpeach && connected && isWebPushNotificationActivated"/>
    <ShowFirebasePushNotification v-if=" !isSpeach && connected && isWebPushNotificationActivated"/>


    <div class="header" v-if="isHeader">
      <Header/>
    </div>
    <div id="layout" :class="layoutClass">
      <!-- MENU -->
      <div id="menuLayout"
           v-if=" !isSpeach && connected && !isFullScreenRoute && !isFullScreenConnectedRoute && isMenuRoute"
           v-show="isMenuOpen">
        <Menu/>
      </div>

      <div id='appLayout' class="appLayout">
        <!-- MAIN LAYOUT -->
        <!--        <transition name="fade">-->
        <router-view appear style="animation-duration: 0.2s" id="routerView" :class="routerClass"/>
        <!--        </transition>-->
      </div>
    </div>

    <!-- LOADING VIEW -->
    <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false"
               v-if="!isFullScreenRoute && !isSpeach">
      <CenterCenter>
        <img alt="logo" src="@/assets/logo/weet_h_gradiant.png" width="300"/>
      </CenterCenter>
    </b-loading>


    <!-- CREATION COMPONENT -->
    <Transition name="fade">
      <div v-show="showPopUpCreation " class="backgroundCreateWeet" :class="{transparent:isSpeach}">
        <Transition enter-active-class="fadeIn"
                    leave-active-class="zoomOut" v-if="showPopUpCreation">
          <div class="advancedEditingStep" :class="createAdvancedEditingClass"
               v-show="showAdvancedEditingShareStep">
            <CreateWeetAdvancedEditingStep v-if="showAdvancedEditingShareStep" @back="returnToPreview">

            </CreateWeetAdvancedEditingStep>
          </div>
        </Transition>
        <div class="createWeetLayout" v-if="showPopUpCreation" :class="createComponentClass"
             data-test="createWeetLayout">
          <CreateWeetComponent ref="createWeetComponent" :disableClosePopup="isFullScreenRoute && !isSpeach"
                               v-if="showPopUpCreation"
                               @close="closeWeetCreation" @step="onNewCreationStep"/>

        </div>
        <Transition enter-active-class="fadeIn"
                    leave-active-class="zoomOut" v-if="showPopUpCreation">
          <div class="settingStep" :class="createSettingStepClass" v-show="showCreationShareStep">
            <CreateWeetSettingStep v-if="showCreationShareStep" @end="endWeetCreation">

            </CreateWeetSettingStep>
          </div>
        </Transition>
      </div>
    </Transition>

    <!-- QUICK LOGIN POPUP -->
    <w-modal v-if="!isSpeach" :active="showPopUpAuth" @close="closeAuthentication">
      <SignInPanel v-if="signIn" :pop-in="true" @close="closeAuthentication" @signUp="signIn=false"
                   @forgotPassword="closeAuthentication"/>
      <SignUp v-else :pop-in="true" @signIn="signIn=true" @close="closeAuthentication"/>
    </w-modal>


    <w-modal v-if="!isSpeach" :modal="false" :active="showPopUpNewProduct" @close="closeProductNews">
      <TwitterNewsProduct/>
    </w-modal>

    <!-- EXTENTION PIN COMPONENET -->
    <div v-if="!isSpeach">
      <PinPlugin v-if="isSetupRoute"/>
    </div>

    <!-- DEBUG COMPONENT FOR TIMELINE -->
    <TimeLineDebugVizualizer v-if="isTimeLineVisu"
                             style="position: absolute;bottom: 0px;left:0px;width: 100%;z-index: 2000"/>

  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import CenterCenter from '@/components/layout/CenterCenter.vue';
import Menu from '@/components/menu/Menu.vue';
import axios from 'axios';
import axiosRetry, {exponentialDelay, isNetworkOrIdempotentRequestError} from 'axios-retry';
import store from '@/store';
import {AUTH_LOGOUT, AUTH_SUCCESS, REFRESH_USER, SHOW_POPUP_AUTH} from '@/store/auth/authAction';
import router, {isToolsRoute} from '@/router';
import {getTenantID} from '@/utils/util';
import {SETTINGS_LIST} from '@/store/settings/settingsAction';
import {OPEN_MENU} from '@/store/setup/setupAction';
import WIcon from '@/components/wrapper/w-icon.vue';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import WModal from '@/components/wrapper/w-modal.vue';
import mobileDetect from 'mobile-device-detect';
import WButton from '@/components/wrapper/w-button.vue';
import {OPEN_EXTENTION, SHOW_POPUP_CREATION} from '@/store/recordingState/recordStateAction';
import CheckPlugin from '@/components/plugin/CheckPlugin.vue';
import CreateWeetComponent from '@/components/recorder/CreateWeetComponent.vue';
import NotificationRefresh from '@/components/notifications/NotificationRefresh.vue';
import {sendMessageToExtention} from '@/utils/extentionUtil';
import {User} from '@/store/user/userModel';
import {setIdentity} from '@/utils/tracker';
import Header from '@/components/header/Header.vue';
import {isGrid1X} from '@/utils/gridBreakPoint';
import PinPlugin from '@/components/plugin/PinPlugin.vue';
import {launchWeetCreation, reinitRecorder} from '@/utils/weetUtil';
import ShowFirebasePushNotification from '@/components/firebaseNotification/ShowFirebasePushNotification.vue';
import RegisterFirebaseToken from '@/components/firebaseNotification/RegisterFirebaseToken.vue';
import {SETTINGUSERS_LIST} from '@/store/settingUsers/settingUserAction';
import ExternalSignIn from '@/components/authPanel/ExternalSignIn.vue';
import SignInPanel from '@/components/authPanel/SignIn.vue';
import SignUp from '@/components/authPanel/SignUp.vue';
import {log} from '@/utils/log';
import TwitterNewsProduct from '@/views/twitter/TwitterNewsProduct.vue';
import {VIEW_PRODUCT_NEWS} from '@/store/appView/appViewAction';
import WorkspaceInvite from '@/components/workspace/WorkspaceInvite.vue';
import CopyWeetButton from '@/components/actionButton/CopyWeetButton.vue';
import CreateWeetSettingStep from '@/components/recorder/createWeetSubComponent/CreateWeetSettingStep.vue';
import {Weet} from '@/store/weet/weetModel';
import {mustShowLastNews} from '@/utils/newsUtil';
import {embededInExternalIframe} from '@/utils/integrationUtil';
import CreateWeetAdvancedEditingStep
  from '@/components/recorder/createWeetSubComponent/CreateWeetAdvancedEditingStep.vue';
import TimeLineDebugVizualizer from '@/components/recorder/timeLine/debug/TimeLineDebugVizualizer.vue';
import {
  GET_MY_WORKSPACES_LIST,
  GET_WORKSPACE_WEET_COUNT
} from '@/store/workspace/workspaceAction';
import {GET_MY_CHANNEL_SUBSCRIPTION, GET_MY_WORKSPACE_CHANNELS} from '@/store/channel/channelAction';
import {ChannelParams} from '@/store/channel/channelModel';
import {GET_WORKSPACE_RIGHTS} from '@/store/workspaceRight/workspaceRightAction';
import delay from 'delay';
import ThemeSelector from '@/components/theme/ThemeSelector.vue';
import {isTestMode} from "@/utils/CypressUtil";
import {SELECT_MODE} from "@/store/advancedEditing/advancedEditingAction";
import {AdvancedEditingMode} from "@/enum/AdvancedEditingMode";
import {isSpeach} from "@/utils/speachUtils";
import {checkSelectedWorkspaceAvailable} from "@/utils/workspaceUtil";
import {SUBTITLES_MANAGEMENT_ENDPOINT} from "@/store/conf/api";
import {SETUP_FROM_SPEACH_TOKEN} from "@/store/persistedInformation/persistAction";

@Component({
  components: {
    ThemeSelector,
    TimeLineDebugVizualizer,
    CreateWeetAdvancedEditingStep,
    CreateWeetSettingStep,
    CopyWeetButton,
    WorkspaceInvite,
    TwitterNewsProduct,
    SignUp,
    SignInPanel,
    ExternalSignIn,
    ShowFirebasePushNotification,
    RegisterFirebaseToken,
    PinPlugin,
    Header,
    NotificationRefresh,
    CreateWeetComponent,
    CheckPlugin,
    WButton,
    WModal,
    WToolTip,
    WIcon,
    CenterCenter,
    Menu
  },
  metaInfo() {
    return {
      // @ts-ignore
      title: this.titlePage
    };
  }
})

export default class App extends Vue {
  private loading: boolean = true;
  private isFullScreenRoute: boolean = false;
  private isAuthRoute: boolean = false;
  private isMenuRoute: boolean = true;
  /*
  * We need this boolean to reproduce the "isAuthRoute" behaviour while user is connected
  * This boolean is a mix between isAuthRoute and isFullScreenRoute
  */
  private isFullScreenConnectedRoute: boolean = false;
  private isMobile: boolean = false;
  private isSetupRoute: boolean = false;
  private isPlayerRoute: boolean = false;

  private signIn: boolean = true;

  private showCreationShareStep: boolean = false;
  private showAdvancedEditingShareStep: boolean = false;

  get isTimeLineVisu(): boolean {
    return store.getters.isTimeLineVisu;
  }

  get connected(): boolean {
    return store.getters.isAuthenticated;
  }


  get isHeader(): boolean {
    return !isSpeach() && !this.isAuthRoute && !this.isFullScreenRoute && !this.isFullScreenConnectedRoute;
  }

  get userConnected(): User {
    return store.getters.userConnected;
  }


  get showPopUpCreation(): boolean {
    return store.getters.showPopUpCreation;
  }

  get createComponentClass(): string {
    let classes = '';
    if (this.showCreationShareStep) {
      classes += 'withShare';
    } else if (this.showAdvancedEditingShareStep) {
      classes += 'withEdit';
    }
    // @ts-ignore
    if (window.Cypress) {
      classes += ' cypress';
    }
    return classes;
  }

  get createAdvancedEditingClass(): string {
    let classes = '';
    if (this.showAdvancedEditingShareStep) {
      classes += ' enter';
    } else {
      classes += ' exit';
    }
    // @ts-ignore
    if (window.Cypress) {
      classes += ' cypress';
    }
    return classes;
  }

  get createSettingStepClass(): string {
    let classes = '';
    if (this.showCreationShareStep) {
      classes += ' enter';
    } else {
      classes += ' exit';
    }
    // @ts-ignore
    if (window.Cypress) {
      classes += ' cypress';
    }
    return classes;
  }

  get showPopUpAuth(): boolean {
    return store.getters.isShowPopUpAuth;
  }

  get showPopUpNewProduct(): boolean {
    return (store.getters.isViewProductNews && store.getters.isAuthenticated) === true;
  }

  get isMenuOpen(): boolean {
    return store.getters.isMenuOpen;
  }

  get isSpeach(): boolean {
    return isSpeach();
  }

  get isRecordAivailable(): boolean {
    return store.getters.isStartRecordingAivailable;
  }

  get isWebPushNotificationActivated(): boolean {
    return store.getters.isWebPushNotificationActivated;
  }

  get routerClass(): string {
    let routerClass = 'routerView ';
    if (this.isAuthRoute || this.isFullScreenConnectedRoute) {
      routerClass += ' authentication ';
    }

    if (this.isFullScreenRoute) {
      routerClass += ' embed';
    }
    if (this.isSpeach) {
      routerClass += ' transparent'
    }
    return routerClass;
  }

  get layoutClass(): string {
    let layoutClass = 'layout ';
    if (!this.isHeader) {
      layoutClass += ' withoutHeader ';
    }
    return layoutClass;
  }

  public async initSettingUsers() {
    await store.dispatch(SETTINGUSERS_LIST);
    await store.dispatch(GET_MY_WORKSPACES_LIST);

    checkSelectedWorkspaceAvailable();
    if (store.getters.getSelectedWorkspaceID) {
      store.dispatch(GET_WORKSPACE_RIGHTS).then(() => {
        store.dispatch(GET_WORKSPACE_WEET_COUNT);
      });
      if (!isSpeach()) {
        store.dispatch(GET_MY_WORKSPACE_CHANNELS,
            new ChannelParams(0));
        store.dispatch(GET_MY_CHANNEL_SUBSCRIPTION,
            new ChannelParams(0));
      }
    }
  }

  public toggleMenu() {
    store.dispatch(OPEN_MENU, !store.getters.isMenuOpen);
  }

  @Watch('connected')
  public onUserConnected() {
    if (this.connected) {
      try {
        if (!isSpeach()) {
          setIdentity(this.userConnected);
          this.$intercom.update({
            email: this.userConnected.contactEmail,
            name: this.userConnected.firstName + ' ' + this.userConnected.lastName,
            user_id: this.userConnected.userID
          });
        }
        // this.checkLastNewsMustShow();
        this.initSettingUsers();
      } catch (e) {
        // Intercom is not already boot
        log.warn(e);
      }
      // if connected from another tabs
      setTimeout(() => {
        // if we are in an authroute, this is an error, so we refresh the page
        if (this.isAuthRoute) {
          window.location.reload();
        }
      }, 500);

    }
  }

  @Watch('$route', {immediate: true, deep: true})
  public onRouteChange(route: any) {
    if (!route.name) {
      return;
    }
    this.isAuthRoute = (route.name === 'Authentication') ||
        (route.name === 'ForgotPassword') ||
        (route.name === 'AuthenticationGoogle') ||
        (route.name === 'RegeneratePassword') ||
        (route.name === 'CreateAccount');

    this.isMenuRoute = route.name !== 'PreviewEmbeddedWeet'
        && route.name !== 'ByeBye'
        && !isToolsRoute(route.name)


    this.isPlayerRoute = route.name === 'PreviewWeet';

    this.isFullScreenRoute = route.name === 'PreviewEmbeddedWeet'
        || route.name === 'CreateWeetEmbed'
        || route.name === 'MyWorkspaceInvite'
        || this.isAuthFromCreateEmbed(route)
        || embededInExternalIframe();


    this.isFullScreenConnectedRoute = route.name === 'CreateWorkspace';

    // Hide intercom if embeded route
    if (!isSpeach()) {
      // launch intercom
      setTimeout(() => {
        this.$intercom.update({
          hide_default_launcher: false
        });
      }, 500);
    }
    if (isGrid1X()) {
      store.dispatch(OPEN_MENU, false);
    } else {
      if (this.isPlayerRoute) {
        store.dispatch(OPEN_MENU, false);
      } else {
        store.dispatch(OPEN_MENU, true);
      }
    }
    this.isSetupRoute = (route.name === 'Setup');


  }

  /**
   * Return true if we are try to register/login from an CreateEmbed route
   * @param route, the current route
   */
  private isAuthFromCreateEmbed(route): boolean {
    const previousRoute = store.getters.urlAsked;
    const createEmbedRoute = this.$router.resolve({name: 'CreateWeetEmbed'});
    const isRegisterOrLoginRoute = this.isAuthRoute || route.name === 'CreateAccount';
    if (previousRoute?.startsWith(createEmbedRoute?.href) && isRegisterOrLoginRoute) {
      return true;
    }
    return false;
  }

  private closeWeetCreation() {
    this.showCreationShareStep = false;
    store.dispatch(SHOW_POPUP_CREATION, false);
    this.showAdvancedEditingShareStep = false;
    // reinit store
    reinitRecorder();

  }

  private endWeetCreation() {
    // @ts-ignore
    this.$refs.createWeetComponent.goToTheRecorderWeet();
  }

  public onNewCreationStep(value: number) {
    const myWeetEditing = store.getters.getMyWeet(store.getters.getEditingWeetID) as Weet;
    if (value === 3 && myWeetEditing) {
      this.showCreationShareStep = false;
      this.showAdvancedEditingShareStep = true;
    } else if (value === 2) {
      if (myWeetEditing && !isSpeach()) {
        this.showCreationShareStep = true;
      } else {
        this.showCreationShareStep = false;
      }
      this.showAdvancedEditingShareStep = false;
    } else if (value === 1) {
      this.showCreationShareStep = false;
      this.showAdvancedEditingShareStep = false;
    }
  }

  public returnToPreview() {
    // @ts-ignore
    this.$refs.createWeetComponent.goToSettingStep();
  }

  public closeAuthentication() {
    store.dispatch(SHOW_POPUP_AUTH, false);
  }


  public closeProductNews() {
    store.dispatch(VIEW_PRODUCT_NEWS, false);
  }

  public onMessageReceive(event) {
    if (event.data !== undefined && event.data.type !== undefined && event.data.type === OPEN_EXTENTION) {
      store.dispatch(SELECT_MODE, AdvancedEditingMode.NONE);
      launchWeetCreation();
    }
  }

  public mounted() {
    window.addEventListener('message', this.onMessageReceive);
    if (!isTestMode() && !isSpeach()) {
      // Init intercom
      this.$intercom.boot();
    }
    if (this.connected) {
      this.onUserConnected();
      // we can wait to refresh the user so we wait 5s
      delay(5000).then(() => {
        store.dispatch(REFRESH_USER);
      });
    }

    // manage mobile
    // if mobile we always close the menu
    this.isMobile = mobileDetect.isMobile;
    if (this.isMobile) {
      store.dispatch(OPEN_MENU, false);
    }

    this.checkLastNewsMustShow();
  }

  public checkLastNewsMustShow() {
    setTimeout(() => {
      // wait a little to compute  if we show the last News
      if (mustShowLastNews() && !this.showPopUpCreation && store.getters.userConnected) {
        store.dispatch(VIEW_PRODUCT_NEWS, true);
      }
    }, 500);
  }

  public created() {
    // define the protection of refresh if synchronise in progress
    window.onbeforeunload = () => {
      if (store.getters.isSychronisationInProgress || store.getters.timelineNeedToBeSaved) {
        return 'Please wait, somes data still to synchronize';
      }
    };

    // //define retry
    axiosRetry(axios, {
      retries: 3,
      retryDelay: exponentialDelay,
      retryCondition: (err): boolean => {
        if (err.response !== undefined) {
          const statuCode = err.response.status;
          const retry = isNetworkOrIdempotentRequestError(err) || statuCode >= 429 || statuCode < 200 || statuCode === 429;
          console.error('send Retry ', retry, statuCode);
          return retry;
        } else {
          return isNetworkOrIdempotentRequestError(err);
        }
      },
    });


    // Define the interceptor for status 401
    axios.interceptors.response.use(undefined, (err) => {
      return new Promise(async (resolve, reject) => {
        if (!err.response) {
          throw err;
        }
        err = err.response;
        if (err && err.status === 401 && err.config && !err.config.__isRetryRequest && !store.getters.noLoginPageRedirection) {
          // if you ever get an unauthorized, logout the user
          await store.dispatch(AUTH_LOGOUT);
          // you can also redirect to /login if needed !
          if (!isSpeach()) {
            router.push('/login');
          } else {
            const query = router.currentRoute.query;
            // Ensure the token is present in the query params
            if (query && query.access_token) {
              console.warn("Oups, authentication is not valid,retry to get en auth form speach token")
              document.location.reload()
            }
          }
        }
        throw err;
      });
    });


    // Define the header tenant and header credential
    axios.interceptors.request.use((config) => {
      const token: string = store.getters.token;

      config.headers.common.tenantID = getTenantID();
      config.headers.common.tenantURL = document.location.origin;
      config.headers.common.clientURL = document.location.href;
      if (token) {
        config.headers.common.Authorization = 'Bearer ' + token;
      }
      return config;
    });


    // load the setting of platform and return 404 if error
    this.loading = true;
    // thie action must be take 0.5s min
    store.dispatch(SETTINGS_LIST).then(() => {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }).catch((err) => {
      this.loading = false;
      router.push({name: 'NotFound'});
    });

    // we send authentication to the plugin if possible

    if (store.getters.token) {
      sendMessageToExtention(AUTH_SUCCESS, {token: store.getters.token, tenantID: getTenantID()});
    }
  }

  public beforeDestroy() {
    // destory the listener
    window.removeEventListener('message', this.onMessageReceive);
  }
}
</script>

<!-- MAIN SCSS-->
<style lang="scss">
@import '@/scss/shadows.scss';
@import '@/scss/gradiant.scss';
@import '@/scss/gridBreakPoint.scss';

#app {
  height: 100%;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 0px;

  &.embed {
    background: transparent;
  }

  #layout {
    display: flex;
    min-height: calc(100vh - 80px);
    margin-top: 80px;
    position: relative;

    &.layout {
      &.withoutHeader {
        margin-top: 0px;
        min-height: calc(100vh - 0px);
      }
    }

    #menuLayout {
      min-width: 300px;
      max-width: 300px;
      min-height: 100%;
      background: #FFFFFF;
      margin-left: 0px;
      position: relative;

      @include GRID1X {
        min-width: 100%;
        max-width: 100%;
      }

    }

    .appLayout {
      min-height: 100%;
      max-height: 100%;
      overflow: auto;
      flex-grow: 1;
      position: relative;

    }


  }

  .loading-background {
    background: #FFFFFF;
  }
}

#routerView {
  position: absolute;
  width: calc(100% - 0px);
  max-width: calc(100% - 0px);
  min-height: calc(100% - 0px);
  height: auto;
  background: #FFFFFF;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  @include GRID1X {
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 0px;
    width: calc(100% - 0px);
    max-width: calc(100% - 0px);
    min-height: calc(100% - 0px);
    border-radius: 0px;
  }

  &.routerView {
    &.authentication {
      background: none;
    }

    &.embed {
      margin: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
      min-height: 100%;
      border-radius: 0;

      &.transparent {
        background: transparent;
      }
    }
  }
}


.backgroundCreateWeet {
  position: absolute;
  animation-duration: 0.2s;
  width: 100%;
  height: 100%;
  background-color: rgba(27, 57, 84, 0.8);

  &.transparent {
    background-color: rgba(230, 230, 230, 0.8);
    backdrop-filter: blur(4px);
  }

  // z-index to put layout on overlay of the player
  z-index: 20;

  .createWeetLayout {
    width: 704px; /* Warning: If this value increase, The width should be changed for Teams pop-up (speacher_integration) */
    height: 568px; /* Warning: If this value increase, the height should also be changed for Teams pop-up (speacher_integration) */
    position: absolute;
    border-radius: 8px;
    left: calc(50% - 352px);
    top: calc(50% - 286px);
    @extend .shadow3;
    transition: all 1.5s ease;
    will-change: left;
    z-index: -1;

    &.withShare {
      left: calc(50% - 524px);
    }

    &.withEdit {
      left: calc(50% - 180px);
    }

    //@include RECORDER3X {
    //  zoom: 80%;
    //}
    //@include RECORDER2X {
    //  zoom: 60%;
    //}

    //&.cypress {
    //  zoom: 100%;
    //}
  }

  .settingStep {
    height: 568px;
    width: 300px;
    background: #FFF;
    position: absolute;
    border-radius: 8px;
    animation-duration: 0.5s;
    will-change: opacity;
    @extend .shadow3;
    top: calc(50% - 286px);
    left: calc(50% + 224px);

    &.enter {
      animation-delay: 0.5s;
    }

    //@include RECORDER3X {
    //  zoom: 80%;
    //}
    //@include RECORDER2X {
    //  zoom: 60%;
    //}
    //
    //&.cypress {
    //  zoom: 100%;
    //}
  }

  .advancedEditingStep {
    height: 568px;
    width: 300px;
    background: #FFF;
    position: absolute;
    border-radius: 8px;
    animation-duration: 0.5s;
    will-change: opacity;
    @extend .shadow3;
    top: calc(50% - 286px);
    left: calc(50% - 524px);
    z-index: -1;

    &.enter {
      animation-delay: 0.5s;
    }

    //@include RECORDER3X {
    //  zoom: 80%;
    //}
    //@include RECORDER2X {
    //  zoom: 60%;
    //}
    //
    //&.cypress {
    //  zoom: 100%;
    //}
  }

}

</style>
